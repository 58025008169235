(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
/** UTM PRESERVER **/	
function getUtmParams(searchParams) {
	let params = {};
	for (const [k, v] of searchParams) {
		if (k.toLowerCase().startsWith('utm_')) {
			params[k] = v;
		}
	};
  
	return params;
}

function appendUtmParameters(oUrl, defaults) {
	let currentUrl = new URL(window.location.href),
			queryParams = getUtmParams(currentUrl.searchParams),
  		preserve;

	console.log(window.location.href, queryParams, Object.keys(queryParams).length);
	if (Object.keys(queryParams).length > 0) {
  	sessionStorage.setItem("bmg_utm_preserve", new URLSearchParams(queryParams));
	}

 	if (sessionStorage.hasOwnProperty('bmg_utm_preserve')) {
		let params = sessionStorage.getItem('bmg_utm_preserve');
			preserve = getUtmParams(new URLSearchParams(params));
	} else {
		preserve = defaults;
	}
  
	for (const key in preserve) {
		if (oUrl.searchParams.has(key)) {
			oUrl.searchParams.set(key, preserve[key]);
		} else {
			oUrl.searchParams.append(key, preserve[key]);
		}
	};

	return oUrl;
}

if ('URLSearchParams' in window) {
	let utmPreserveElements = document.querySelectorAll('.preserve_utm'),
			defaults = {
				utm_source   : 'website',
				utm_campaign : 'test_ep'
			};
		
	if (!window.sessionStorage) {
		console.error('Requires SessionStorage');
	} else {
		console.info('SessioStorage enabled, activating UTM preserver');
	}
      
	for (const element of utmPreserveElements) {
		let source = element.getAttribute('data-source'),
				campaign = element.getAttribute('data-campaign');

		if (source !== null) {
			defaults.utm_source = source;
		}

		if (campaign !== null) {
			defaults.utm_campaign = campaign;
		}


    if (element.href !== undefined) {
      let oUrl = new URL(element.href);
      oUrl = appendUtmParameters(oUrl, defaults);
      element.href = oUrl.toString();
    } else if (element.src !== undefined) {
			let oUrl = new URL(element.src);
      oUrl = appendUtmParameters(oUrl, defaults);
      element.src = oUrl.toString();
		}
	}
}
/** END UTM PRESERVER **/

},{}]},{},[1]);
